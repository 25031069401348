import { Headline, Highlight, P } from 'ui-kit/typography';
import Tag, { TagGroup } from 'ui-kit/tag';

import Button from 'ui-kit/button';
import Container from 'ui-kit/container';
import Image from 'ui-kit/image';
import Intro from 'ui-kit/intro';
import Layout from '../components/layout';
import PropTypes from 'prop-types';
import React from 'react';
import Section from 'ui-kit/section';
import Stage from 'ui-kit/stage';
import TeaserSection from '../components/teaser-section';
import { fontSizes } from 'helpers/variables';
import { getImageFromNode } from 'helpers/utils';
import { graphql } from 'gatsby';
import styled from 'styled-components';

/** Erzeugt einen Span */
const TagLabel = styled.span`
    ${fontSizes.xs};
    display: block;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
`;

/** Wrapper with margins for Button */
const ButtonWrapper = styled.span`
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
`;

const BrandsAndProductsPage = ({ data }) => {
    const { brandImages } = data;

    return (
        <Layout description="Marken und Produkte">
            <Stage
                image={data.stageImage.childImageSharp}
                imageAlt="Tranchiertes Rinderfilet auf buntem Gemüse"
                tag="Unsere Marken & Produkte"
                headline={{ text: 'Genießen Sie die Vielfalt', level: 'h1' }}
                bottomShade
            />
            <Section>
                <Intro
                    title="Gehen Sie auf eine kulinarische Entdeckungsreise"
                    cta={<Highlight>Hier gibt es Gutes für jeden Geschmack!</Highlight>}
                >
                    Ob saftiges Steak, Münchner Weißwurst oder echt bayerischer Leberkäs, ob
                    saisonale Wildspezialitäten, leckere Würstchen, auch fast ohne Fett, oder eine
                    unserer vielen weiteren Köstlichkeiten – unsere Marken mit insgesamt über 250
                    Wurstwaren und 800 weiteren Spezialitäten von regionalen Metzgereien finden Sie
                    an den Bedienungstheken und in SB-Kühlregalen ausgewählter EDEKA Märkte.
                </Intro>
            </Section>
            <Section bg="gray">
                <Container
                    size="l"
                    gap="xxxxl"
                    grid
                    layout="6-6"
                    cols="2"
                    alignItems="center"
                    reverseOnMobile
                >
                    <div>
                        <Headline level="h3">Mein Bayern - genial regional</Headline>
                        <P gap="l">
                            Frisch und lecker für jeden Geschmack. Entdecken Sie unsere beliebten
                            bayerischen Spezialitäten – in erstklassiger Qualität für echte
                            Genießer.
                        </P>
                        <TagGroup gap="xl">
                            <TagLabel>Erhältlich im / an der:</TagLabel>
                            <Tag bgColor="white" textColor="sbf|primary" layer={1} size="xs">
                                SB-Regal
                            </Tag>
                        </TagGroup>
                        <ButtonWrapper>
                            <Button size="s" layer={1} linkTo="https://genial-regional.de">
                                Genial regional
                            </Button>
                        </ButtonWrapper>
                        <Button size="s" layer={1} linkTo="https://www.edeka-meinbayern.de">
                            Mein Bayern Wurstprodukte
                        </Button>
                    </div>
                    <Image
                        {...getImageFromNode(
                            brandImages,
                            'brands-and-products-page/markenteaser-regional.jpg'
                        ).node.childImageSharp}
                        alt="Verpackungen genial regional nebeneinadner angeordnet"
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                    />
                </Container>
                <Container size="l" gap="xxxxl" grid layout="6-6" cols="2" alignItems="center">
                    <Image
                        {...getImageFromNode(
                            brandImages,
                            'brands-and-products-page/markenteaser-bayerisches-strohschwein.jpg'
                        ).node.childImageSharp}
                        alt="Verpackungen der Marke 'Bayerisches Strohschwein' nebeneinadner angeordnet"
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                    />
                    <div>
                        <Headline level="h3">
                            Bayerisches Strohschwein – natürlich aus der Region
                        </Headline>
                        <P gap="l">
                            Durch und durch bayerisch. Aufgewachsen mit viel Auslauf und Stroh,
                            ausgestattet mit dem Siegel „Geprüfte Qualität Bayern“ – für alle, die
                            gerne nachhaltig genießen.
                        </P>
                        <TagGroup gap="xl">
                            <TagLabel>Erhältlich im / an der:</TagLabel>
                            <Tag bgColor="white" textColor="sbf|primary" layer={1} size="xs">
                                SB-Regal
                            </Tag>
                            <Tag bgColor="white" textColor="sbf|primary" layer={1} size="xs">
                                Bedienungstheke
                            </Tag>
                        </TagGroup>
                        <Button size="s" layer={1} linkTo="https://www.bayerisches-strohschwein.de">
                            Zur Marken-Website
                        </Button>
                    </div>
                </Container>
            </Section>
            <TeaserSection boxes={['originAndQuality', 'recipes']} bg="white" />
        </Layout>
    );
};

BrandsAndProductsPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        brandImages: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(
            relativePath: { eq: "brands-and-products-page/buehne-marken-und-produkte.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        brandImages: allFile(
            filter: { relativePath: { glob: "brands-and-products-page/markenteaser-*.jpg" } }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 605, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default BrandsAndProductsPage;
